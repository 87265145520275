import React from "react";
import {Datagrid, List, TextField} from 'react-admin';

const CustomerList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="billingAddress.firstName" label="First Name" sortable={false}/>
            <TextField source="billingAddress.lastName" label="Last Name" sortable={false}/>
            <TextField source="billingAddress.company" label="Company" sortable={false}/>
            <TextField source="customerOrigin"/>
            <TextField source="customerDestination"/>
            <TextField source="originId"/>
            <TextField source="destinationId"/>
        </Datagrid>
    </List>
);

export default CustomerList;