import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Datagrid, DateField, List, ListContextProvider, TextField, useGetList, useListContext } from 'react-admin';
import CustomerReferenceField from "../customers/CustomerReferenceField";
import CustomNumberField from "./CustomNumberField";
import { Divider, Tab, Tabs } from '@material-ui/core';
import SendInvoideButton from "./SendInvoideButton";
import OrderStatusChipField from "./OrderStatusChipField";


const tabs = [
    { id: 'new', name: 'new' },
    { id: 'ordered', name: 'ordered' },
    { id: 'delivered', name: 'delivered' },
    { id: 'backorder', name: 'backorder' },
    { id: 'invoiced', name: 'invoiced' },
];

const useGetTotals = (filterValues) => {
    const { total: totalNew } = useGetList(
        'order',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'new' }
    );
    const { total: totalOrdered } = useGetList(
        'order',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'ordered' }
    );
    const { total: totalDelivered } = useGetList(
        'order',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'delivered' }
    );
    const { total: totalBackorder } = useGetList(
        'order',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'backorder' }
    );
    const { total: totalInvoiced } = useGetList(
        'order',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'invoiced' }
    );
    return {
        new: totalNew,
        ordered: totalOrdered,
        delivered: totalDelivered,
        backorder: totalBackorder,
        invoiced: totalInvoiced,
    };
};


const PostBulkActionButtons = props => (
    <Fragment>
        <SendInvoideButton {...props} />
    </Fragment>
);

const TabbedDatagrid = props => {
    const listContext = useListContext();
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    const [newOrders, setNewOrders] = useState([]);
    const [ordered, setOrdered] = useState([]);
    const [delivered, setDelivered] = useState([]);
    const [backorder, setBackorder] = useState([]);
    const [invoiced, setInvoiced] = useState([]);
    const totals = useGetTotals(filterValues);

    useEffect(() => {
        if (ids && ids !== filterValues.status) {
            switch (filterValues.status) {
                case 'new':
                    setNewOrders(ids);
                    break;
                case 'ordered':
                    setOrdered(ids);
                    break;
                case 'delivered':
                    setDelivered(ids);
                    break;
                case 'backorder':
                    setBackorder(ids);
                    break;
                case 'invoiced':
                    setInvoiced(ids);
                    break;
                default:
                    break;

            }
        }
    }, [ids, filterValues.status]);

    const handleChange = useCallback(
        (event, value) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );
    useEffect(() => {
        if (filterValues.status == null) {
            handleChange(null, 'new')
        }
    }, [filterValues.status, handleChange])
    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status ?? 'new'}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.name]
                                ? `${choice.name} (${totals[choice.name]})`
                                : choice.name
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <div>
                {filterValues.status === 'new' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: newOrders }}
                    >
                        <Datagrid rowClick={id => `order/${id}/supplier`}>
                            <TextField source="orderNumber" label="Number" />
                            <DateField source="placedAt" label="Order Date" />
                            <OrderStatusChipField source="orderStatus" label="Status" />
                            <CustomerReferenceField />
                            <CustomNumberField label="Total" />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'ordered' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: ordered }}
                    >
                        <Datagrid rowClick="edit">
                            <TextField source="orderNumber" label="Number" />
                            <DateField source="placedAt" label="Order Date" />
                            <OrderStatusChipField source="orderStatus" label="Status" />
                            <CustomerReferenceField />
                            <CustomNumberField label="Total" />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'delivered' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: delivered }}
                    >
                        <Datagrid rowClick="show" hasBulkActions={true}>
                            <TextField source="orderNumber" label="Number" />
                            <DateField source="placedAt" label="Order Date" />
                            <OrderStatusChipField source="orderStatus" label="Status" />
                            <CustomerReferenceField />
                            <CustomNumberField label="Total" />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'backorder' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: backorder }}
                    >
                        <Datagrid rowClick="show">
                            <TextField source="orderNumber" label="Number" />
                            <DateField source="placedAt" label="Order Date" />
                            <OrderStatusChipField source="orderStatus" label="Status" />
                            <CustomerReferenceField />
                            <CustomNumberField label="Total" />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'invoiced' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: invoiced }}
                    >
                        <Datagrid rowClick="show">
                            <TextField source="orderNumber" label="Number" />
                            <DateField source="placedAt" label="Order Date" />
                            <OrderStatusChipField source="orderStatus" label="Status" />
                            <CustomerReferenceField />
                            <CustomNumberField label="Total" />
                        </Datagrid>
                    </ListContextProvider>
                )}
            </div>
        </Fragment>
    );
};

const OrderList = props => (
    <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedDatagrid />
    </List>
);

export default OrderList;