import {Confirm, SaveButton, TextInput, useNotify} from 'react-admin';
import {Box, Toolbar, Typography} from '@material-ui/core';
import {Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import _ from 'lodash';
import {useHistory} from "react-router";

// the parent component (Edit or Create) injects these props to their child
const ProductPriceListForm = ({basePath, record, save, saving, version}) => {
    const client = useSelector(state => state.user.client);
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [deletingItems, setDeletingItems] = useState(0);
    const [formSaving, setFormSaving] = useState(false);
    const history = useHistory();
    const submit = (values, saveFlag) => {
        setOpen(true)
    };
    const [items, setItems] = useState([]);

    const getDeletedItems = () => {
        client.post('/majestic/Product/getitemstodelete', {products: items, status: 'InProgress'}).then(i => {
            setDeletingItems(i.data.productsToDelete);
        })
    }
    const onInputChange = (value) => {
        setItems(value);
    }
    const debounceGetDeletedItems = _.debounce(getDeletedItems, 100);
    useEffect(() => {
        debounceGetDeletedItems();
    }, [debounceGetDeletedItems, items])

    const getItemArray = (value) => {
        let skus = value.split('\n');
        let lastItem = skus.pop();
        skus = skus.map(pd => {
            let data = pd.split("\t");
            if (data.length === 1){
                data = data[0].split(",");
            }
            return {
                id: data[0],
                isStockProduct: !!data[1]
            }
        });
        skus = skus.filter(i => i.id !== "" && i.id !== "" && !isNaN(parseInt(i.id)))
        let lastItemData = lastItem.split("\t");
        if (lastItemData.length === 1){
            lastItemData = lastItemData[0].split(",");
        }
        if(lastItemData[0] !== "" && lastItemData[0] !== "" && !isNaN(parseInt(lastItemData[0])) && lastItemData.length > 1){
            lastItem = {
                id: lastItemData[0],
                isStockProduct: !!lastItemData[1]
            }
            skus.push(lastItem);
        }
        return skus;
    }

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        // React-final-form removes empty values from the form state.
        // To allow users to *delete* values, this must be taken into account
        setFormSaving(true);
        client.post('/majestic/Product/pricelist', {products: items, status: 'InProgress'}).then(i => {
            setFormSaving(false);
            notify('ra.notification.created', 'info', {
                smart_count: 1,
            });
            history.push('/majestic/product');
        }).catch(e => {
            notify(e.message, 'error', {
                smart_count: 1,
            });
            setFormSaving(false);
        })
    };

    return (
        <Form
            initialValues={record}
            submitOnEnter={false}
            onSubmit={submit}
            mutators={{
                ...arrayMutators, setInput: (args, state, utils) => {
                    utils.changeValue(state, 'itemIds', () => args)
                },
            }} // necessary for ArrayInput
            subscription={defaultSubscription} // don't redraw entire form each time one field changes
            key={version} // support for refresh button
            keepDirtyOnReinitialize
            sanitizeEmptyValues={false}
            render={formProps => {
                return (
                    <form>
                        <Box p="1em">
                            <Box display="flex">
                                <Box flex={2} mr="1em">

                                    <Typography variant="h6" gutterBottom>Pricelist Upload</Typography>
                                    <Typography variant="subtitle1" gutterBottom><b>Warning!</b> </Typography>
                                    <Typography variant="subtitle1" gutterBottom>Submbitting a new pricelist
                                        will remove all non-blacklisted Majestic products not included in this
                                        list.</Typography>

                                    <TextInput required multiline name="itemIds" source="itemIds" label="Product Id's"
                                               fullWidth
                                               onChange={(e) => {
                                                   let itemArray = getItemArray(e.target.value);
                                                   onInputChange(itemArray);
                                               }}/>


                                    <Typography variant="h6"
                                                gutterBottom>{`${items.filter(i => i.id !== "" && i.id !== "" && !isNaN(parseInt(i.id))).length} products entered.`}</Typography>
                                    <Typography variant="h6"
                                                gutterBottom>{`${deletingItems} products to be removed.`}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Toolbar>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Fragment>
                                    <SaveButton
                                        saving={formSaving}
                                        handleSubmitWithRedirect={formProps.handleSubmit}
                                    />
                                    <Confirm
                                        isOpen={open}
                                        loading={formSaving}
                                        title="Upload Pricelist"
                                        content={`Are you sure you want to update the pricelist? ${deletingItems} products will be removed.`}
                                        onConfirm={handleConfirm}
                                        onClose={handleDialogClose}
                                    />
                                </Fragment>
                            </Box>
                        </Toolbar>
                    </form>
                )
            }}
        />
    )
};
const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};
export default ProductPriceListForm;
