import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import {Button, Dialog, DialogActions, DialogContent, TextField} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useDataProvider, useNotify} from "react-admin";

const useStyles = makeStyles({
    button: {
        fontSize: "1.2rem"
    },
    formControl: {
        marginTop: "2em",
        width: "100%",
    },
    selectEmpty: {},
});

export default function OnboardDialog() {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [open, setOpen] = React.useState(true);
    const [supplier, setSupplier] = React.useState("");
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const email = useSelector(state => state.user.user.email);
    const [user, setUser] = useState(null);
    const notify = useNotify();
    const client = useSelector(state => state.user.client);

    useEffect(() => {
        dataProvider.getOne('user', {id: null})
            .then(({data}) => {
                setSupplier(data.suppliers ? data.suppliers[0] : null)
                setUser(data);
                setStepsEnabled(!data.finishedTutorial);
            })
    }, [dataProvider])

    const handleClose = () => {
        setOpen(false);
    };
    const handleNext = async () => {
        try {
            setupSupplier(client, supplier, user.edcApiKey, user?.edcEmailAddress ?? email ?? "", notify);
            setOpen(false);
        } catch (error) {
        }
    };

    const handleSupplierChange = (event) => {
        setSupplier(event.target.value);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Container maxWidth="sm" style={{paddingTop: "2em"}}>
                        <Typography variant="h2" component="h2" gutterBottom>
                            Welcome to Finway!
                        </Typography>
                        <Typography variant="h6" component="h6" gutterBottom>
                            Finway let's you connect <b>any</b> supplier to your webshop for Automated Product Import
                            and Order Placement.
                        </Typography>
                        <Typography variant="h6" component="h6" gutterBottom>
                            These are the suppliers you can select out of the box, select one or <Link
                            href="mailto:support@finway.tech?&subject=Could%20you%20add%20this%20supplier">
                            request one.
                        </Link>
                        </Typography>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Supplier</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={supplier}
                                onChange={handleSupplierChange}
                                label="Supplier"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Edc"}>EDC</MenuItem>
                                <MenuItem value={"Complies"}>Complies Computer B.V.</MenuItem>
                            </Select>
                            {supplier === "Edc" ?
                                <div>
                                    <TextField required style={{marginTop: "2em"}} id="standard-basic"
                                               fullWidth
                                               value={user?.edcApiKey ?? ""}
                                               onChange={(e) => setUser({
                                                   ...user,
                                                   edcApiKey: e.target.value
                                               })}
                                               label="API key"/>
                                    <TextField required
                                               style={{marginBottom: "1em", marginTop: "1em"}}
                                               id="standard-basic"
                                               fullWidth
                                               value={user?.edcEmailAddress ?? email ?? ""}
                                               onChange={(e) => setUser({
                                                   ...user,
                                                   edcEmailAddress: e.target.value
                                               })}
                                               label="Email Address"/>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="edc-update-url"><Link
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.one-dc.com/nl/my_overview/edit-details">
                                            EDC Update URL
                                        </Link></InputLabel>
                                        <Input
                                            id="edc-update-url"
                                            style={{margin: "1em 0"}} id="standard-basic"
                                            fullWidth
                                            value={user?.edcCallbackUrl ?? ""}
                                            disabled
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="copy"
                                                        onClick={() => navigator.clipboard.writeText(user?.edcCallbackUrl)}

                                                    >
                                                        <FileCopyIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }/></FormControl></div> : null}
                        </FormControl>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} onClick={handleClose}>Leave me alone</Button>
                    <Button className={classes.button} onClick={handleNext} color="primary" autoFocus>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const setupSupplier = (client, supplier, edcApiKey, edcEmailAddress, notify) => {
    client.patch("/user", {suppliers: [supplier], edcApiKey, edcEmailAddress}).then(({data}) => {
        notify("Supplier selected", "info")
    }).catch((e) =>
        notify("Something went wrong", "error")
    )
}