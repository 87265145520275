import * as React from 'react';
import {Checkbox, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LoopIcon from '@material-ui/icons/Loop';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
    rightAlignedCell: {textAlign: 'right'},
    rotate: {
        animation: "$rotate infinite 3s linear"
    },
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)"
        },

        "100%": {
            transform: "rotate(360deg)"
        }
    }

});

const Basket = ({record, setLineItems}) => {
    const classes = useStyles();

    if (!record) return null;
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Reference
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Unit Price
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Quantity
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Backorder
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Delivered
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        In Stock
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Total
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.lineItems.map(
                    (item) =>
                        (
                            <TableRow key={item.productId}>
                                <TableCell>
                                    <span>{item.name}</span>
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.productPrice.value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: item.productPrice.currency,
                                    })}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.quantity}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.backorder}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.delivered}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {
                                        item.selectedStock?.inStock == null ? null : item.selectedStock.inStock ?
                                            <CheckCircleIcon/> : <CancelIcon/>
                                    }
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.total.value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: item.total.currency,
                                    })}
                                </TableCell>
                            </TableRow>
                        )
                )}
            </TableBody>
        </Table>
    );
};

export default Basket;
