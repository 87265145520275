import * as React from "react";
import {useEffect, useState} from "react";
import {Card} from "@material-ui/core";
import ProductPriceListForm from "./ProductPriceListForm";
import {Title} from "react-admin";

const ProductCreatePriceList = props => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems([{originId: 123124}])
    }, [])
    useEffect(() => {
    }, [items])
    return (
        <Card>
            <Title title="Product Pricelist"/>
            <ProductPriceListForm/>
        </Card>
    );
};

export default ProductCreatePriceList;