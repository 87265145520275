import * as React from 'react';
import {DateField, Edit, Labeled, ReferenceField, TextField, useEditController} from 'react-admin';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {Box, Card, CardContent, Grid, Typography,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import BasketEdit from './BasketEdit';
import Totals from '../Totals';
import {Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import OrderEditActions from "./OrderEditActions";


const OrderTitle = ({record}) => {
    return record ? (
        <span>
            {`Order ${record.orderNumber}`}
        </span>
    ) : null;
};

const CustomerDetails = ({record}) => (
    <Box display="flex" flexDirection="column">
        <Typography
            component={RouterLink}
            color="primary"
            to={`/customer/${record?.id}`}
            style={{textDecoration: 'none'}}
        >
            {record?.billingAddress.firstName} {record?.billingAddress.lastName}
        </Typography>
        <Typography
            component={RouterLink}
            color="primary"
            to={`/customer/${record?.id}`}
            style={{textDecoration: 'none'}}
        >
            {record?.billingAddress.company}
        </Typography>
    </Box>
);

const CustomerAddress = ({record}) => (
    <Box>
        <Typography>
            {record?.shippingAddress?.firstName} {record?.shippingAddress?.lastName}
        </Typography>
        <Typography>{record?.shippingAddress?.addressLine1}</Typography>
        <Typography>{record?.shippingAddress?.addressLine2}</Typography>
        <Typography>
            {record?.shippingAddress?.city}, {record?.shippingAddress?.state} {record?.shippingAddress?.zipCode}
        </Typography>
        <Typography>
            {record?.shippingAddress?.country}
        </Typography>
    </Box>
);


const Spacer = () => <Box m={1}>&nbsp;</Box>;


const OrderForm = ({basePath, record, save, saving, version}) => {
    return (
        <Form
            onSubmit={() => {
            }}
            initialValues={record}
            submitOnEnter={false}
            mutators={{
                ...arrayMutators, setInput: (args, state, utils) => {
                    utils.changeValue(state, 'itemIds', () => args)
                },
            }} // necessary for ArrayInput
            subscription={defaultSubscription} // don't redraw entire form each time one field changes
            key={version} // support for refresh button
            keepDirtyOnReinitialize
            sanitizeEmptyValues={false}
            render={formProps => {
                return (
                    <form>
                        <Box maxWidth="65em">
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <Typography variant="h6" gutterBottom>
                                                Order
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Labeled
                                                        source="placedAt"
                                                        resource="order"
                                                    >
                                                        <DateField
                                                            source="placedAt"
                                                            resource="order"
                                                            record={record}
                                                        />
                                                    </Labeled>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Labeled
                                                        source="orderNumber"
                                                        resource="order"
                                                    >
                                                        <TextField
                                                            source="orderNumber"
                                                            resource="order"
                                                            record={record}
                                                        />
                                                    </Labeled>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Typography variant="h6" gutterBottom>
                                                Customer
                                            </Typography>
                                            <ReferenceField
                                                source="customerId"
                                                resource="order"
                                                reference="customer"
                                                basePath="/customer"
                                                record={record}
                                                link={false}
                                            >
                                                <CustomerDetails/>
                                            </ReferenceField>
                                            <Spacer/>

                                            <Typography variant="h6" gutterBottom>
                                                Shipping Address
                                            </Typography>
                                            <ReferenceField
                                                source="customerId"
                                                resource="order"
                                                reference="customer"
                                                basePath="/customer"
                                                record={record}
                                                link={false}
                                            >
                                                <CustomerAddress/>
                                            </ReferenceField>
                                        </Grid>
                                    </Grid>
                                    <Spacer/>

                                    <Typography variant="h6" gutterBottom>
                                        Items
                                    </Typography>
                                    <Box>
                                        <BasketEdit record={{...record}}/>
                                    </Box>
                                    <Spacer/>

                                    <Typography variant="h6" gutterBottom>
                                        Total
                                    </Typography>
                                    <Box>
                                        <Totals record={record}/>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </form>
                )
            }}
        />
    );
}

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};
const OrderEdit = props => {
    const {id} = useParams();
    const classes = useStyles();
    const createControllerProps = useEditController({
        basePath: "/order", resource: "order", id: id, "hasList": true,
        hasEdit: true,
        hasShow: true,
        hasCreate: false
    });
    const {
        record, // empty object, unless some values were passed in the location state to prefill the form
    } = createControllerProps;
    if (!record) {
        return null;
    }
    return (
        <Edit {...props} title={<OrderTitle/>} actions={<OrderEditActions/>} className={classes.root}>
            <OrderForm {...createControllerProps}/>
        </Edit>
    );
}

const useStyles = makeStyles({
    root: {width: '65em'},
    spacer: {height: 20},
    invoices: {margin: '10px 0'},
});
export default OrderEdit;
