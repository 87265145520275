import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Confirm,
    DateField,
    Labeled,
    ReferenceField,
    TextField,
    Toolbar,
    useEditController,
    useNotify
} from 'react-admin';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {Box, Button, Card, CardContent, Grid, Typography,} from '@material-ui/core';
import {useHistory} from "react-router";
import Basket from './Basket';
import Totals from '../Totals';
import {Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import {useSelector} from "react-redux";
import StorefrontIcon from '@material-ui/icons/Storefront';

const CustomerDetails = ({record}) => (
    <Box display="flex" flexDirection="column">
        <Typography
            component={RouterLink}
            color="primary"
            to={`/customer/${record?.id}`}
            style={{textDecoration: 'none'}}
        >
            {record?.billingAddress.firstName} {record?.billingAddress.lastName}
        </Typography>
        <Typography
            component={RouterLink}
            color="primary"
            to={`/customer/${record?.id}`}
            style={{textDecoration: 'none'}}
        >
            {record?.billingAddress.company}
        </Typography>
    </Box>
);

const CustomerAddress = ({record}) => (
    <Box>
        <Typography>
            {record?.shippingAddress?.firstName} {record?.shippingAddress?.lastName}
        </Typography>
        <Typography>{record?.shippingAddress?.addressLine1}</Typography>
        <Typography>{record?.shippingAddress?.addressLine2}</Typography>
        <Typography>
            {record?.shippingAddress?.city}, {record?.shippingAddress?.state} {record?.shippingAddress?.zipCode}
        </Typography>
        <Typography>
            {record?.shippingAddress?.country}
        </Typography>
    </Box>
);

const Spacer = () => <Box m={1}>&nbsp;</Box>;


const OrderForm = ({basePath, record, save, saving, version}) => {
    const [formSaving, setFormSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const client = useSelector(state => state.user.client);
    const [lineItems, setLineItems] = useState(record.lineItems);
    const history = useHistory();
    const [stateValues, setStateValues] = useState(record);
    const notify = useNotify();
    const handleDialogClose = () => setOpen(false);
    const submit = values => {
        setStateValues(values);
        setOpen(true);
    };
    const handleConfirm = () => {
        setFormSaving(true);
        client.patch(`/order/${stateValues.id}`, {
            lineItems: lineItems.map(li => ({
                id: li.id,
                delivered: 0,
                backorder: li.quantity
            }))
        }).then(() => {
            notify("Items Ordered", "info");
            setFormSaving(false);
            history.push(`/order/${stateValues.id}`);
        });
    };
    return (
        <Form
            initialValues={record}
            submitOnEnter={false}
            onSubmit={submit}
            mutators={{
                ...arrayMutators, setInput: (args, state, utils) => {
                    utils.changeValue(state, 'itemIds', () => args)
                },
            }} // necessary for ArrayInput
            subscription={defaultSubscription} // don't redraw entire form each time one field changes
            key={version} // support for refresh button
            keepDirtyOnReinitialize
            sanitizeEmptyValues={false}
            render={formProps => {
                return (
                    <form>
                        <Box maxWidth="65em">
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <Typography variant="h6" gutterBottom>
                                                Order
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Labeled
                                                        source="placedAt"
                                                        resource="order"
                                                    >
                                                        <DateField
                                                            source="placedAt"
                                                            resource="order"
                                                            record={record}
                                                        />
                                                    </Labeled>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Labeled
                                                        source="orderNumber"
                                                        resource="order"
                                                    >
                                                        <TextField
                                                            source="orderNumber"
                                                            resource="order"
                                                            record={record}
                                                        />
                                                    </Labeled>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Typography variant="h6" gutterBottom>
                                                Customer
                                            </Typography>
                                            <ReferenceField
                                                source="customerId"
                                                resource="command"
                                                reference="customer"
                                                basePath="/customer"
                                                record={record}
                                                link={false}
                                            >
                                                <CustomerDetails/>
                                            </ReferenceField>
                                            <Spacer/>

                                            <Typography variant="h6" gutterBottom>
                                                Shipping Address
                                            </Typography>
                                            <ReferenceField
                                                source="customerId"
                                                resource="command"
                                                reference="customer"
                                                basePath="/customer"
                                                record={record}
                                                link={false}
                                            >
                                                <CustomerAddress/>
                                            </ReferenceField>
                                        </Grid>
                                    </Grid>
                                    <Spacer/>

                                    <Typography variant="h6" gutterBottom>
                                        Items
                                    </Typography>
                                    <Box>
                                        <Basket record={{...record, lineItems: lineItems}} setLineItems={setLineItems}/>
                                    </Box>
                                    <Spacer/>

                                    <Typography variant="h6" gutterBottom>
                                        Total
                                    </Typography>
                                    <Box>
                                        <Totals record={record}/>
                                    </Box>
                                </CardContent>
                                <Toolbar
                                    record={record}
                                    basePath={formProps.basePath}
                                    undoable={true}
                                    invalid={formProps.invalid}
                                    resource="products"
                                >
                                    <Button
                                        variant="contained"
                                        onClick={formProps.handleSubmit}
                                        startIcon={<StorefrontIcon/>}
                                        color={'primary'}
                                    >
                                        Order at supplier
                                    </Button>
                                    <Confirm
                                        isOpen={open}
                                        loading={formSaving}
                                        title="Order Items"
                                        content={`Are you sure you want to order these components?`}
                                        onConfirm={handleConfirm}
                                        onClose={handleDialogClose}
                                    />
                                </Toolbar>
                            </Card>
                        </Box>
                    </form>
                )
            }}
        />
    );
}

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};
const OrderSupplier = props => {
    const {id} = useParams();
    const createControllerProps = useEditController({
        basePath: "/order", resource: "order", id: id, "hasList": true,
        hasEdit: true,
        hasShow: true,
        hasCreate: false
    });
    const {
        record, // empty object, unless some values were passed in the location state to prefill the form
    } = createControllerProps;
    if (!record) {
        return null;
    }
    return (
        <OrderForm {...createControllerProps}/>
    );
}

export default OrderSupplier;
