import { SaveButton, SelectInput, TextInput, useNotify, useRedirect } from 'react-admin';
import { Box, Toolbar, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

// the parent component (Edit or Create) injects these props to their child
const ProductForm = ({ basePath, record, save, saving, version }) => {
    const client = useSelector(state => state.user.client);
    const redirect = useRedirect();
    const notify = useNotify();
    const [formSaving, setFormSaving] = useState(false);
    const submit = (values, saveFlag) => {
        // React-final-form removes empty values from the form state.
        // To allow users to *delete* values, this must be taken into account
        setFormSaving(true);
        client.post('/majestic/Product/batch', { products: items, status: values.status }).then(i => {
            setFormSaving(false);
            notify('ra.notification.created', 'info', {
                smart_count: 1,
            });
            redirect('list', basePath);
        }).catch(e => {
            notify(e.message, 'error', {
                smart_count: 1,
            });
            setFormSaving(false);
        })
    };
    const [items, setItems] = useState([]);
    const onInputChange = (value) => {
        setItems(value);
    }
    const getItemArray = (value) => {
        let skus = value.split('\n');
        let lastItem = skus.pop();
        skus = skus.map(pd => {
            let data = pd.split("\t");
            if (data.length === 1) {
                data = data[0].split(",");
            }
            return {
                id: data[0],
                isStockProduct: !!data[1]
            }
        });
        skus = skus.filter(i => i.id !== "" && i.id !== "" && !isNaN(parseInt(i.id)))
        let lastItemData = lastItem.split("\t");
        if (lastItemData.length === 1) {
            lastItemData = lastItemData[0].split(",");
        }
        if (lastItemData[0] !== "" && lastItemData[0] !== "" && !isNaN(parseInt(lastItemData[0])) && lastItemData.length > 1) {
            lastItem = {
                id: lastItemData[0],
                isStockProduct: !!lastItemData[1]
            }
            skus.push(lastItem);
        }
        return skus;
    }

    return (
        <Form
            initialValues={record}
            submitOnEnter={false}
            onSubmit={submit}
            mutators={{
                ...arrayMutators, setInput: (args, state, utils) => {
                    utils.changeValue(state, 'itemIds', () => args)
                },
            }} // necessary for ArrayInput
            subscription={defaultSubscription} // don't redraw entire form each time one field changes
            key={version} // support for refresh button
            keepDirtyOnReinitialize
            sanit
            render={formProps => {
                return (
                    <form>
                        <Box p="1em">
                            <Box display="flex">
                                <Box flex={2} mr="1em">

                                    <Typography variant="h6" gutterBottom>Product Batch</Typography>

                                    <TextInput required multiline name="itemIds" source="itemIds" label="Product Id's"
                                        fullWidth
                                        onChange={(e) => {
                                            let itemArray = getItemArray(e.target.value);
                                            onInputChange(itemArray);
                                        }} />


                                    <Typography variant="h6"
                                        gutterBottom>{`${items.filter(i => i.id !== "" && i.id !== "" && !isNaN(parseInt(i.id))).length} products entered.`}</Typography>
                                    <SelectInput source="status" label="Product Status" fullWidth
                                        initialValue={"InProgress"}
                                        choices={[
                                            { id: 'InProgress', name: 'Sync' },
                                            { id: 'BlackListed', name: 'Black Listed' },
                                        ]} />
                                </Box>
                            </Box>
                        </Box>
                        <Toolbar>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <SaveButton
                                    saving={formSaving}
                                    handleSubmitWithRedirect={formProps.handleSubmit}
                                />
                            </Box>
                        </Toolbar>
                    </form>
                )
            }}
        />
    )
};
const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};
export default ProductForm;
