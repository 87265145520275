import * as React from 'react';
import {ReferenceField} from 'react-admin';

import FullNameField from './FullNameField';

const CustomerReferenceField = props => {
    return (
    <ReferenceField source="customerId" reference="customer" resource={"customer"} basePath={"/customer"} record={props.record} >
        <FullNameField/>
    </ReferenceField>
)};

CustomerReferenceField.defaultProps = {
    source: 'customerId',
    addLabel: true,
};

export default CustomerReferenceField;
