import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';


const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const PendingOrders= ({ orders = [], customers = {} }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader title="Processing Orders" />
            <List dense={true}>
                {orders.map(record => {
                    let customer = customers[record.customerId];
                    return (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/order/${record.id}`}
                    >
                        <ListItemAvatar>
                            {customers[record.customerId] ? (
                                <Avatar
                                />
                            ) : (
                                <Avatar />
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={new Date(record.placedAt).toLocaleString(
                                'nl-NL'
                            )}
                            secondary={`Ordered by ${customer?.billingAddress?.firstName} ${customer?.billingAddress?.lastName} ${customer?.billingAddress?.company ?? ""}, `}
                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                €{record.total.value}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                )})}
            </List>
        </Card>
    );
};

export default PendingOrders;
