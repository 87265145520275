import {makeStyles} from "@material-ui/core/styles";
import {Box, Card, Divider, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import * as React from "react";

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        background: 'linear-gradient(45deg,#f3f9fd,#888ffdd6)',
        boxShadow: '0 3px 6px 0 rgba(41,70,97,.15)'
    },
    main: () => ({
        overflow: 'inherit',
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
        },
    }),
    title: {
    },
    text:{
        padding: '1em'
    }
}));

const WelcomeBanner = props => {
    const {title, subtitle, to, children} = props;
    const classes = useStyles(props);
    return (
        <Card className={classes.card}>
            <Link to={"/configuration"}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                    </Box>
                    <Box textAlign="center">
                        <Box textAlign="left" className={classes.text}>
                            <Typography
                                variant="h5" component="h2"
                            >
                                Welcome to Finway!
                            </Typography>
                            <Typography color="textSecondary">
                                Ready to get going? Click me to setup a supplier.
                            </Typography>
                        </Box>
                    </Box>
                </div>
            </Link>
            {children && <Divider/>}
            {children}
        </Card>
    );
};

export default WelcomeBanner;