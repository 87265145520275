import {stringify} from 'qs';
import {FinwayClient} from "../library/client";

const httpClient = FinwayClient("");
const DataProvider = () => {
    return {
        getList: (resource, params) => {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const originalResource = getResource(resource);
            const filters = getFilters(params.filter, resource);
            const query = {
                sort: order.toLowerCase() === 'asc' ? field : `-${field}`,
                page: {number: page, size: perPage},
                filters:filters
            };
            const url = `/${originalResource}?${stringify(query)}`;

            return httpClient(url).then(({data}) => ({
                data: data.data,
                total: data.total,
            }));
        },

        getOne: (resource, params) => {
            if (!params?.id) {
                return httpClient.get(`/${getResource(resource)}`).then(({data}) => ({
                    data: data,
                }))
            }
            return httpClient.get(`/${getResource(resource)}/${params.id}`).then(({data}) => ({
                data: data,
            }))
        },

        getMany: (resource, params) => {
            const {page, perPage} = params.pagination ?? {page:1, perPage: 10};
            const query = {
                filterId: params.ids,
                page: {number: page, size: perPage},
            };
            const url = `/${getResource(resource)}?${stringify(query)}`;
            return httpClient(url).then(({data}) => ({data: data.data}));
        },

        getManyReference: (resource, params) => {
            const {page, perPage} = params.pagination ?? {page:1, perPage: 10};
            const {field, order} = params.sort;
            const query = {
                sort: order.toLowerCase() === 'asc' ? field : `-${field}`,
                page: {number: page, size: perPage},
                filter: params.filter,
                filterId: params.id,
            };
            const url = `/${getResource(resource)}?${stringify(query)}`;

            return httpClient(url).then(({data}) => ({data: data.data}));
        },

        update: (resource, params) =>
            httpClient.patch(`/${getResource(resource)}/${params.id}`,
                params.data
            ).then(({data}) => ({data: data})),

        updateMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };
            return httpClient(`/${getResource(resource)}?${stringify(query)}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({data}) => ({data: data}));
        },

        create: (resource, params) =>
            httpClient(`/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({data}) => ({
                data: {...params.data, id: data.id},
            })),

        delete: (resource, params) =>
            httpClient(`/${getResource(resource)}/${params.id}`, {
                method: 'DELETE',
            }).then(({data}) => ({data: data})),

        deleteMany: (resource, params) => {
            const query = {
                filterId: params.ids,
            };
            return httpClient(`/${getResource(resource)}?${stringify(query)}`, {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            }).then(({data}) => ({data: data.data}));
        }
    }
};
const getResource = (path) => {
    let urlParts = path.split("/");
    return urlParts[urlParts.length-1];
}

const getFilters = (filter, path) => {
    let urlParts = path.split("/");
    let resource = urlParts[urlParts.length-1];
    switch (resource) {
        case "product":
            return {...filter};
        default:
            return filter;
    }
}

export default DataProvider();