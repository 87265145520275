import React, {useCallback, useEffect, useState} from 'react';
import {useDataProvider, useVersion} from 'react-admin';
import {useMediaQuery} from '@material-ui/core';
import {subDays} from 'date-fns';

import MonthlyRevenue from './MonthlyRevenue';
import NbProcessingOrders from './NbProcessingOrders';
import OrderChart from './OrderChart';
import {useSelector} from "react-redux";
import PendingOrders from "./PendingOrders";
import WelcomeBanner from "./WelcomeBanner";
import OnboardDialog from "./OnboardDialog";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

const Spacer = () => <span style={{width: '1em'}}/>;
const VerticalSpacer = () => <span style={{height: '1em'}}/>;


const Dashboard = () => {
    const client = useSelector(state => state.user.client);
    const [state, setState] = useState({});
    const [user, setUser] = useState(null);
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    );
    useEffect(() => {
        dataProvider.getOne('user', {id: null})
            .then(({data}) => {
                setUser(data);
            })
    }, [dataProvider])

    const fetchOrders = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        const {data: recentOrders} = await dataProvider.getList(
            'order',
            {
                filter: {
                    date: {
                        from: aMonthAgo.toISOString()
                    },
                    processingStatus: "InProgress"
                },
                sort: {field: 'PlacedAt', order: 'DESC'},
                pagination: {page: 1, perPage: 300},
            }
        );
        const aggregationsRevenue = (await client.get('Totals/revenue')).data.value;
        const aggregationsProcessingOrders = (await client.get('Totals/processingorders')).data.value;
        setState(state => ({
            ...state,
            revenue: aggregationsRevenue.toLocaleString(undefined, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }),
            nbProcessingOrders: aggregationsProcessingOrders,
            pendingOrders: recentOrders,
        }));
        const {data: customers} = await dataProvider.getMany(
            'customer',
            {
                ids: recentOrders.map(
                    (order) => order.customerId
                ),
                pagination: {
                    perPage: 300
                }
            }
        );
        setState(state => ({
            ...state,
            pendingOrdersCustomers: customers.reduce(
                (prev, customer) => {
                    prev[customer.id] = customer; // eslint-disable-line no-param-reassign
                    return prev;
                },
                {}
            ),
        }));
    }, [dataProvider]);


    useEffect(() => {
        fetchOrders();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        nbProcessingOrders,
        nbPendingReviews,
        pendingOrders,
        pendingOrdersCustomers,
        pendingReviews,
        pendingReviewsCustomers,
        revenue,
    } = state;
    return isXSmall ? (
        <div>
            {/*{user?.finishedTutorial ?? true ? null : <OnboardDialog/>}*/}
            <div style={styles.flexColumn}>
                {user?.finishedTutorial ?? true ? null : <VerticalSpacer/>}
                {user?.finishedTutorial ?? true ? null : <WelcomeBanner/>}
                {user?.finishedTutorial ?? true ? null : <VerticalSpacer/>}
                <MonthlyRevenue value={revenue}/>
                <VerticalSpacer/>
                <NbProcessingOrders value={nbProcessingOrders ?? 0}/>
                <VerticalSpacer/>
                <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                />
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn}>
            {/*{user?.finishedTutorial ?? true ? null : <OnboardDialog/>}*/}
            <div style={styles.singleCol}>
                {user?.finishedTutorial ?? true ? null : <WelcomeBanner/>}
            </div>
            <div style={styles.flex}>
                <MonthlyRevenue value={revenue}/>
                <Spacer/>
                <NbProcessingOrders value={nbProcessingOrders ?? 0}/>
            </div>
            <div style={styles.singleCol}>
                <OrderChart/>
            </div>
            <div style={styles.singleCol}>
                <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                />
            </div>
        </div>
    ) : (
        <>
            {/*{user != null && (user?.suppliers == null || user?.suppliers?.length === 0)  ?  <OnboardDialog/>: null}*/}
            <div style={styles.flexColumn}>
                <div style={styles.singleCol}>
                    {user?.finishedTutorial ?? true ? null : <WelcomeBanner/>}
                </div>
                <div style={styles.flex}>
                    <MonthlyRevenue value={revenue}/>
                    <Spacer/>
                    <NbProcessingOrders value={nbProcessingOrders ?? 0}/>
                </div>
                <div style={styles.singleCol}>
                    <OrderChart/>
                </div>
                <div style={styles.singleCol}>
                    <PendingOrders
                        orders={pendingOrders}
                        customers={pendingOrdersCustomers}
                    />
                </div>
            </div>
        </>
    );
};

export default Dashboard;
