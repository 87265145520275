import OrderIcon from '@material-ui/icons/AttachMoney';
import OrderList from './OrderList';
import OrderShow from "./OrderShow";
import OrderEdit from "./OrderEdit/OrderEdit";

const Orders = {
    edit: OrderEdit,
    list: OrderList,
    icon: OrderIcon,
    show: OrderShow
};
export default Orders;