// LoginPage.js
import React from "react";
import {Login, LoginForm} from "react-admin";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import ForgotPasswordButton from './ForgotPassword'
import {Store} from '../store';
import {login} from "../configuration/actions";
import jwtDecode from "jwt-decode";

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '#/',
    callbacks: {},
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
};
const getSignInSuccesCallbackFunction = (returnUrl, queryParams) => {
    return (
        // tslint:disable-next-line:no-any firebase dependency not available.
        authResult,
        redirectUrl
    ) => {
        let user = jwtDecode(authResult.user._lat);
        Store.dispatch(login({
            token: authResult.user._lat,
            user: {
                name: user.name,
                id: user.sub,
            }
        }));
        window.location.assign(`${returnUrl ?? '/'}${queryParams ?? ''}`);
    }
}

const SignInScreen = ({redirectUrl, queryParams}) => {
    uiConfig.callbacks.signInSuccessWithAuthResult = getSignInSuccesCallbackFunction(redirectUrl, queryParams)
    return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
};

const CustomLoginForm = props => (
    <div>
        <SignInScreen redirectUrl={props.redirectUrl} queryParams={props.queryParams}/>
    </div>
);

const CustomLoginPage = props => {
    const urlParams = new URLSearchParams(window.location.search);
    let queryData = {};

    for (const entry of urlParams.entries()) {
        queryData[entry[0]] = entry[1];
    }
    return <Login {...props}>
        <CustomLoginForm {...props} queryParams={window.location.search} redirectUrl={queryData.redirectUrl}/>
    </Login>
};

export default CustomLoginPage;