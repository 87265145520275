import React, { cloneElement } from "react";
import {
    Button,
    ChipField,
    CreateButton,
    Datagrid,
    ExportButton,
    Filter,
    List,
    sanitizeListRestProps,
    SelectInput,
    TextField,
    NumberField,
    TextInput,
    TopToolbar,
    useListContext,
} from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';
import { useHistory } from "react-router";
import SkuField from "./SkuField";

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    const history = useHistory();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="SKU" source="sku" defaultValue="" alwaysOn />
        <SelectInput source="status" label="Product Status" alwaysOn
            choices={[
                { id: 'InProgress', name: 'Sync' },
                { id: 'BlackListed', name: 'Black Listed' },
                { id: 'Done', name: 'Done' },
                { id: 'Failed', name: 'Failed' },
            ]} />
    </Filter>
);

const ProductList = props => (
    <List {...props} title="Products" filters={<ProductFilter />} actions={<ListActions />}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Name" />
            <SkuField label="Sku" />
            <TextField source="brand" label="Brand" />
            <NumberField source="price.value" label="Price" options={{ style: 'currency', currency: 'EUR' }} />
            <ChipField source="status" label="Sync Status" />
            <TextField source="stockStatus" label="Stock Product" />
        </Datagrid>
    </List>
);

export default ProductList;