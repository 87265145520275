import config from '../config';
import axios from 'axios';
import jwtDecode from "jwt-decode";
import {AuthProvider, Store} from '../store'
import {login} from "../configuration/actions";
import {HttpError} from 'react-admin';

export const FinwayClient = (token) => {
    let instance = axios.create({
        baseURL: config.finway.endpointUrl,
        headers: {'Authorization': 'Bearer ' + token}
    });
    instance.interceptors.request.use(req => {
            req.headers = {
                ...req.headers,
                'Authorization': 'Bearer ' + Store.getState().user.token,
            }
            if (req.method === "PATCH" || req.method === 'patch') {
                req.headers = {
                    ...req.headers,
                    'Authorization': 'Bearer ' + Store.getState().user.token,
                    'content-type': 'application/json-patch+json'
                }
            }
            return req;
        },
        (error) => {
            throw new HttpError(error.request?.statusText, error.request?.status, error.request?.data);
        })

    instance.interceptors.response.use(
        response => {
            return response;
        },
        (error) => {
            return new Promise((resolve, reject) => {
                if (error.response?.status === 401) {
                    return AuthProvider.getAuthUser().then(
                        function (adminuser) {
                            return adminuser.getIdToken(true);
                        })
                        .then(token => {
                            let user = jwtDecode(token);
                            Store.dispatch(login({
                                token: token,
                                user: {
                                    name: user.name,
                                    id: user.sub,
                                }
                            }))
                            return token;
                        }).then(idToken => {
                            error.config.headers['Authorization'] = 'Bearer ' + idToken;
                            error.config.baseURL = config.finway.endpointUrl;
                            return resolve(axios.request(error.config));
                        });
                }
                return reject(new HttpError(error.response?.statusText ?? "Could not connect to server.", error.response?.status ?? 500, error.response?.data));
            });
        });

    return instance;
};
