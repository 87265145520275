import * as React from 'react';
import {useSelector} from 'react-redux';
import {Layout, Sidebar} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import {darkTheme, lightTheme} from './themes';

const CustomSidebar = (props) => <Sidebar {...props} size={200}/>;

const LayoutComp = (props) => {
    const theme = useSelector((state) => {
            return state.theme === 'dark' ? darkTheme : lightTheme
        }
    );
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
        />
    );
};

export default LayoutComp;