import ProductIcon from '../Icons/ProductIcon';

import ProductList from './ProductList';
import ProductEdit from "./ProductEdit";
import ProductCreate from "./ProductCreate";

const Products = {
    edit: ProductEdit,
    list: ProductList,
    icon: ProductIcon,
    create: ProductCreate,
};

export default Products;
