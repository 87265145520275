export const CHANGE_THEME = 'CHANGE_THEME';
export const LOGIN = 'LOGIN';

export const changeTheme = (theme) => ({
    type: CHANGE_THEME,
    payload: theme,
});

export const login = (payload) => ({
    type: LOGIN,
    payload: payload
});