import {DateField, Labeled, ReferenceField, Show, TextField, TopToolbar, useShowController} from 'react-admin';
import * as React from "react";
import {Box, Button, Card, CardContent, Grid, Typography} from "@material-ui/core";
import Totals from "./Totals";
import ShowBasket from "./ShowBasket";
import {Link as RouterLink} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import GetAppIcon from '@material-ui/icons/GetApp';

const OrderTitle = ({record}) => {
    return record ? (
        <span>
            {`Order ${record.orderNumber}`}
        </span>
    ) : null;
};

const CustomerDetails = ({record, classes}) => (
    <Box>
        <p className={classes.customerLink}>
            <Typography
                component={RouterLink}
                color="primary"
                to={`/customer/${record?.id}`}
                style={{textDecoration: 'none'}}
            >
                {record?.billingAddress.firstName} {record?.billingAddress.lastName}
            </Typography>
        </p>
        <p className={classes.customerLink}>
            <Typography
                component={RouterLink}
                color="primary"
                to={`/customer/${record?.id}`}
                style={{textDecoration: 'none'}}
            >
                {record?.billingAddress.company}
            </Typography>
        </p>
    </Box>
);

const CustomerAddress = ({record}) => (
    <Box>
        <Typography>
            {record?.shippingAddress?.firstName} {record?.shippingAddress?.lastName}
        </Typography>
        <Typography>{record?.shippingAddress?.addressLine1}</Typography>
        <Typography>{record?.shippingAddress?.addressLine2}</Typography>
        <Typography>
            {record?.shippingAddress?.city}, {record?.shippingAddress?.state} {record?.shippingAddress?.zipCode}
        </Typography>
        <Typography>
            {record?.shippingAddress?.country}
        </Typography>
    </Box>
);


const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderShowAction = ({basePath, data, resource}) => {
    return (<TopToolbar>
        <Button color="primary" onClick={() => {
            let styleTags = Array.from(document.getElementsByTagName('style'));
            styleTags = styleTags.reduce((acc, curr) => acc + curr.innerText, "");
            const style = `<style>${styleTags}</style>`;
            const print_div = document.getElementById("pakbon");
            const print_area = window.open();
            print_area.document.write(print_div.innerHTML + style);
            print_area.document.title = `Order ${data.originId}`;
            print_area.document.close();
            print_area.focus();
            print_area.print();
            print_area.close();
        }}><GetAppIcon/> Pakbon</Button>
    </TopToolbar>);
};
const useStyles = makeStyles({
    root: {width: '65em'},
    spacer: {height: 20},
    invoices: {margin: '10px 0'},
    customerLink: {
        padding: 0,
        margin: 0
    }
});
const OrderShow = props => {
    const classes = useStyles();
    const {record} = useShowController(props);
    if (!record) return null;
    return (
        <Show {...props} actions={<OrderShowAction/>} title={<OrderTitle/>} className={classes.root}>
            <Card id="pakbon">
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography variant="h6" gutterBottom>
                                Order
                            </Typography>
                            <Grid>
                                <Labeled
                                    source="placedAt"
                                    resource="order"
                                >
                                    <DateField
                                        source="placedAt"
                                        resource="order"
                                        record={record}
                                    />
                                </Labeled>
                            </Grid>
                            <Grid>
                                <Labeled
                                    source="orderNumber"
                                    resource="order"
                                >
                                    <TextField
                                        source="orderNumber"
                                        resource="order"
                                        record={record}
                                    />
                                </Labeled>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Customer
                            </Typography>
                            <ReferenceField
                                source="customerId"
                                resource="command"
                                reference="customer"
                                basePath="/customer"
                                record={record}
                                link={false}
                            >
                                <CustomerDetails classes={classes}/>
                            </ReferenceField>
                            <Spacer/>

                            <Typography variant="h6" gutterBottom>
                                Shipping Address
                            </Typography>
                            <ReferenceField
                                source="customerId"
                                resource="command"
                                reference="customer"
                                basePath="/customer"
                                record={record}
                                link={false}
                            >
                                <CustomerAddress/>
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Spacer/>

                    <Typography variant="h6" gutterBottom>
                        Items
                    </Typography>
                    <Box>
                        <ShowBasket record={record}/>
                    </Box>
                    <Spacer/>

                    <Typography variant="h6" gutterBottom>
                        Total
                    </Typography>
                    <Box>
                        <Totals record={record}/>
                    </Box>
                </CardContent>
            </Card>
        </Show>
    )
}
export default OrderShow;
