import * as React from 'react';
import {Edit, FormWithRedirect, TextInput, Toolbar,} from 'react-admin';
import {Box, Card, CardContent, Typography} from '@material-ui/core';

import FullNameField from './FullNameField';

const CustomerEdit = props => {
    return (
        <Edit
            title={<CustomerTitle/>}
            component="div"
            {...props}
        >
            <CustomerForm/>
        </Edit>
    );
};

const CustomerAddress = ({record}) => (
    <Box>
        <Typography>
            {record?.shippingAddress?.company}
        </Typography>
        <Typography>
            {record?.shippingAddress?.firstName} {record?.shippingAddress?.lastName}
        </Typography>
        <Typography>{record?.shippingAddress?.addressLine1} {record?.shippingAddress?.addressLine2}</Typography>
        <Typography>
            {record?.shippingAddress?.city}, {record?.shippingAddress?.state} {record?.shippingAddress?.zipCode}
        </Typography>
        <Typography>
            {record?.shippingAddress?.country}
        </Typography>
    </Box>
);

const CustomerBillingAddress = ({record}) => (
    <Box>
        <Typography>
            {record?.billingAddress?.company}
        </Typography>
        <Typography>
            {record?.billingAddress?.firstName} {record?.billingAddress?.lastName}
        </Typography>
        <Typography>{record?.billingAddress?.addressLine1} {record?.billingAddress?.addressLine2}</Typography>
        <Typography>
            {record?.billingAddress?.city}, {record?.billingAddress?.state} {record?.billingAddress?.zipCode}
        </Typography>
        <Typography>
            {record?.billingAddress?.country}
        </Typography>
    </Box>
);

const CustomerTitle = ({record}) =>
    record ? <FullNameField record={record} size="32"/> : null;

const CustomerForm = (props) => {

    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Customer
                            </Typography>
                            <Box display={{md: 'block', lg: 'flex'}}>
                                <Box flex={2} mt="1em" mr={{md: 0, lg: '1em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        Billing Address
                                    </Typography>
                                    <CustomerBillingAddress record={formProps.record}/>
                                </Box>
                                <Box flex={2} mt="1em" mr={{md: 0, lg: '1em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        Shipping Address
                                    </Typography>

                                    <CustomerAddress record={formProps.record}/>
                                </Box>
                            </Box>
                            <Box mt="1em"/>
                            <Box display={{md: 'block', lg: 'flex'}}>
                                <Box flex={2} mr={{md: 0, lg: '1em'}}>
                                    <Typography variant="h6" gutterBottom>
                                        Identity
                                    </Typography>
                                    <TextInput
                                        type="text"
                                        source="destinationId"
                                        resource="customer"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                undoable={true}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="customer"
                            />
                        </CardContent>
                    </form>
                </Card>
            )}
        />
    );
};

export default CustomerEdit;
