import * as React from 'react';
import {memo} from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
}));

const FullNameField = ({record, size}) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {record.billingAddress.firstName} {record.billingAddress.lastName}
        </div>
    ) : null;
};

FullNameField.defaultProps = {
    source: 'lastName',
    label: 'resources.customer.fields.name',
};

export default memo(FullNameField);
