import * as React from 'react';

const Logo = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="46.5478" cy="26.6976" rx="25.3837" ry="24.9535" stroke="#1F3C88" strokeWidth="3.44186"/>
            <ellipse cx="27.6181" cy="46.4878" rx="25.3837" ry="25.814" stroke="#1F3C88" strokeWidth="3.44186"/>
        </svg>
    );
};

export default Logo;
