import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {Button, Confirm, TopToolbar, useNotify} from 'react-admin';
import UndoIcon from '@material-ui/icons/Undo';
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const OrderEditActions = ({basePath, data, resource}) => {
    const client = useSelector(state => state.user.client);
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [confirm, setConfirm] = useState(<div/>);
    const notify = useNotify();
    const history = useHistory();
    useEffect(() => {
        let list = data.lineItems.filter((item) => item.orderStatus === "Ordered").map((curr) => {
            return <li>{`Sku: ${curr.sku} | Supplier: ${curr.supplier}`}</li>
        });
        setConfirm(<div>Are you sure you want to reset the following ordered items?
            <ul>{list}</ul>
        </div>)
    }, [data.lineItems])
    const handleConfirm = () => {
        setSaving(true);
        client.patch(`/order/${data.id}`, {
            lineItems: data.lineItems.filter((item) => item.orderStatus === "Ordered").map(li => ({
                id: li.id,
                status: "NotOrdered",
                delivered: 0,
                backorder: li.quantity
            }))
        }).then(() => {
            notify("Order reverted", "info");
            setSaving(false);
            history.push(`/order/${data.id}/supplier`);
        });
    };
    const handleDialogClose = () => {
        setOpen(false);
    }
    return (<TopToolbar>
            <Fragment><Button color="primary" label={"Revert to new"} onClick={() => {
                setOpen(true);
            }}><UndoIcon/></Button>
                <Confirm
                    isOpen={open}
                    loading={saving}
                    title="Revert order"
                    content={confirm}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </Fragment>
        </TopToolbar>
    );
}
export default OrderEditActions;
