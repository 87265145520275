import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import {Confirm, Title, Toolbar, useDataProvider, useNotify} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import {Form} from "react-final-form";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {useHistory} from "react-router";
import {Steps} from "intro.js-react";
import "intro.js/introjs.css";

const useStyles = makeStyles({
    label: {width: '10em', display: 'inline-block'},
    button: {marginTop: '1em', width: '100%'},
    deleteButton: {
        color: 'red'
    },
    card: {marginTop: '1em'},
    toolbar: {background: 'none'}
});

const Configuration = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [webshopProvider, setWebshopProvider] = useState(null);
    const [accountingProvider, setAccountingProvider] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [user, setUser] = useState(null);
    const theme = useSelector((state) => state.theme);
    const client = useSelector(state => state.user.client);
    const email = useSelector(state => state.user.user.email);
    const notify = useNotify();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmRequest, setConfirmRequest] = useState(false);
    const [automaticOrderPlacement, setAutomaticOrderPlacement] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const steps = [
        {
            element: ".supplier-select",
            intro: "Select a supplier here!"
        },
        {
            element: ".auto-order-select",
            intro: "Toggle me for placing orders automatically at your selected supplier."
        }
    ];
    const storeUser = useSelector(state => state.user.user.id);
    const history = useHistory();

    useEffect(() => {
        if (!storeUser) {
            history.replace(`/login?${window.location.search}`)
        }
    });

    useEffect(() => {
        dataProvider.getOne('user', {id: null})
            .then(({data}) => {
                setWebshopProvider(data.webshopProvider);
                setAccountingProvider(data.accountingProvider);
                setSupplier(data.suppliers ? data.suppliers[0] : null)
                setAutomaticOrderPlacement(data.automaticOrderPlacement)
                setUser(data);
                setStepsEnabled(!data.finishedTutorial);
            })
    }, [dataProvider])

    return (
        <div>
            <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={0}
                onExit={() => {
                    setStepsEnabled(false)
                    finishTutorial(client);
                }}
            />
            <Card>
                <Title title="Configuration"/>
                <CardContent>
                    <Typography>
                        Connection settings
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <div>
                            <Box maxWidth={'20em'} margin="2em">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    setupWebshop(client, webshopProvider, user);
                                }}>
                                    <FormControl fullWidth>
                                        <Box>
                                            <Box display="flex" p={0} textAlign={'left'}>
                                                <Box display="flex" alignSelf="center" component="span" p="0 1em 0 0">Webshop
                                                    Provider</Box> {user?.webshopProvider ? <CheckIcon/> : <ClearIcon/>}
                                            </Box>
                                            <Select fullWidth value={webshopProvider ?? ""}
                                                    onChange={({target}) => setWebshopProvider(target.value)}>
                                                {/*<MenuItem value="WooCommerce">WooCommerce</MenuItem>*/}
                                                <MenuItem value="Shopify">Shopify</MenuItem>
                                            </Select>
                                            {webshopProvider === "WooCommerce" ?
                                                <TextField required style={{margin: "1em 0"}} id="standard-basic"
                                                           fullWidth
                                                           value={user?.wooCommerceBaseAddress ?? ""}
                                                           onChange={(e) => setUser({
                                                               ...user,
                                                               wooCommerceBaseAddress: e.target.value
                                                           })}
                                                           label="Url"/> : null}
                                        </Box>
                                        <Button
                                            type="submit"
                                            variant="outlinedPrimary"
                                            className={classes.button}
                                            color={theme === 'dark' ? 'primary' : 'default'}
                                        >
                                            Setup
                                        </Button>
                                    </FormControl>
                                </form>
                            </Box>
                            {/*<Box maxWidth={'20em'} margin="2em">*/}
                            {/*    <form onSubmit={(e) => {*/}
                            {/*        e.preventDefault();*/}
                            {/*        setupAccounting(client, accountingProvider);*/}
                            {/*    }}>*/}
                            {/*        <FormControl fullWidth>*/}
                            {/*            <Box>*/}
                            {/*                <Box display="flex" p={0} textAlign={'left'}>*/}
                            {/*                    <Box display="flex" alignSelf="center" component="span" p="0 1em 0 0">Accounting*/}
                            {/*                        Provider</Box> {user?.accountingProvider ? <CheckIcon/> :*/}
                            {/*                    <ClearIcon/>}*/}
                            {/*                </Box>*/}
                            {/*                <Box>*/}
                            {/*                    <Typography>*/}
                            {/*                        Token:*/}
                            {/*                    </Typography>*/}
                            {/*                </Box>*/}
                            {/*                <Select fullWidth value={accountingProvider ?? ""}*/}
                            {/*                        onChange={({target}) => setAccountingProvider(target.value)}>*/}
                            {/*                    <MenuItem value="Asperion">Asperion</MenuItem>*/}
                            {/*                </Select>*/}
                            {/*            </Box>*/}
                            {/*            <Button*/}
                            {/*                type="submit"*/}
                            {/*                variant="outlinedPrimary"*/}
                            {/*                margin={0}*/}
                            {/*                className={classes.button}*/}
                            {/*                color={theme === 'dark' ? 'primary' : 'default'}*/}
                            {/*            >*/}
                            {/*                Setup*/}
                            {/*            </Button>*/}
                            {/*        </FormControl>*/}
                            {/*    </form>*/}
                            {/*</Box>*/}
                        </div>
                        <div>
                            <Box maxWidth={'20em'} margin="2em">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    setupSalesMargin(client, user.salesMarginPercentage, notify);
                                }}>
                                    <FormControl fullWidth>
                                        <Box>
                                            <TextField required style={{margin: "1em 0"}} id="standard-basic" fullWidth
                                                       value={user?.salesMarginPercentage ?? ""}
                                                       type="number"
                                                       onChange={(e) => setUser({
                                                           ...user,
                                                           salesMarginPercentage: e.target.value
                                                       })}
                                                       label="Sales Margin Percentage"/>
                                            <Button
                                                type="submit"
                                                variant="outlinedPrimary"
                                                margin={0}
                                                className={classes.button}
                                                color={theme === 'dark' ? 'primary' : 'default'}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </FormControl>
                                </form>
                            </Box>

                            <Box maxWidth={'20em'} margin="2em">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    setupSupplier(client, user, supplier, notify);
                                }}>
                                    <FormControl fullWidth>
                                        <Box class="supplier-select">
                                            <Box display="flex" p={0} textAlign={'left'}>
                                                <Box display="flex" alignSelf="center" component="span" p="0 1em 0 0">
                                                    Supplier </Box>

                                            </Box>
                                            <Select fullWidth value={supplier ?? ""}
                                                    onChange={({target}) => {
                                                        setSupplier(
                                                            target.value
                                                        )
                                                    }}>
                                                <MenuItem value="Edc">EDC</MenuItem>
                                                <MenuItem value="CompliesComputerBv">Complies Computer BV</MenuItem>
                                                {/*<MenuItem value="Sokken">Sokken</MenuItem>*/}
                                            </Select>
                                            {supplier === "Edc" ?
                                                <div>
                                                    <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                               fullWidth
                                                               value={user?.edcApiKey ?? ""}
                                                               onChange={(e) => setUser({
                                                                   ...user,
                                                                   edcApiKey: e.target.value
                                                               })}
                                                               label="API key"/>
                                                    <TextField required
                                                               style={{marginBottom: "1em", marginTop: "0.3em"}}
                                                               id="standard-basic"
                                                               fullWidth
                                                               value={user?.edcEmailAddress ?? email ?? ""}
                                                               onChange={(e) => setUser({
                                                                   ...user,
                                                                   edcEmailAddress: e.target.value
                                                               })}
                                                               label="Email Address"/>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="edc-update-url"><Link
                                                            target="_blank"
                                                            rel="noopener"
                                                            href="https://www.one-dc.com/nl/my_overview/edit-details">
                                                            EDC Update URL
                                                        </Link></InputLabel>
                                                        <Input
                                                            id="edc-update-url"
                                                            style={{margin: "1em 0"}} id="standard-basic"
                                                            fullWidth
                                                            value={user?.edcCallbackUrl ?? ""}
                                                            disabled
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="copy"
                                                                        onClick={() => navigator.clipboard.writeText(user?.edcCallbackUrl)}

                                                                    >
                                                                        <FileCopyIcon/>
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }/></FormControl></div> : null}
                                            {/*{supplier === "Sokken" ?*/}
                                            {/*    <div>*/}
                                            {/*        <TextField required style={{marginTop: "1em"}} id="standard-basic"*/}
                                            {/*                   fullWidth*/}
                                            {/*                   value={user?.edcApiKey ?? ""}*/}
                                            {/*                   onChange={(e) => setUser({*/}
                                            {/*                       ...user,*/}
                                            {/*                       edcApiKey: e.target.value*/}
                                            {/*                   })}*/}
                                            {/*                   label="API key"/>*/}
                                            {/*        <FormControl fullWidth>*/}
                                            {/*        </FormControl></div> : null}*/}
                                            {supplier === "CompliesComputerBv" ?
                                                <div>
                                                    <TextField required style={{marginTop: "1em"}} id="standard-basic"
                                                               fullWidth
                                                               value={user?.compliesData?.apiKey ?? ""}
                                                               onChange={(e) => setUser({
                                                                   ...user,
                                                                   compliesData: {
                                                                       apiKey: e.target.value
                                                                   }
                                                               })}
                                                               label="API key"/>
                                                    <FormControl fullWidth>
                                                    </FormControl></div> : null}
                                            <Button
                                                type="submit"
                                                variant="outlinedPrimary"
                                                margin={0}
                                                className={classes.button}
                                                color={theme === 'dark' ? 'primary' : 'default'}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </FormControl>
                                </form>
                            </Box>
                        </div>
                    </Grid>
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <Typography>
                        Automation
                    </Typography>
                    <FormGroup row>
                        <FormControlLabel
                            className="auto-order-select"
                            labelPlacement="bottom"
                            control={
                                <Switch
                                    checked={automaticOrderPlacement}
                                    onChange={() => {
                                        let newOrderPlacement = !automaticOrderPlacement;
                                        setAutomaticOrderPlacement(newOrderPlacement)
                                        setupAutomaticOrderPlacement(client, newOrderPlacement, notify);
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Auto order at supplier"
                        />
                    </FormGroup>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                    </Grid>
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardContent>
                    <Typography>
                        Account Settings
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Form
                            initialValues={{}}
                            submitOnEnter={false}
                            onSubmit={(values) => {
                            }}
                            keepDirtyOnReinitialize
                            sanitizeEmptyValues={false}
                            render={({handleSubmit, form, submitting, pristine, values}) => {
                                return (
                                    <form>
                                        <Toolbar className={classes.toolbar}>
                                            <Button
                                                variant="contained"
                                                className={classes.deleteButton}
                                                onClick={() => setConfirmDelete(true)}
                                                startIcon={<DeleteIcon/>}
                                            >
                                                Delete Account
                                            </Button>
                                            <Confirm
                                                isOpen={confirmDelete}
                                                title="Delete account"
                                                content={`Are you sure you want to delete your entire account?
                                                You will be signed out, and all your data will be deleted.
                                                THIS CANNOT BE UNDONE.`}
                                                onConfirm={(e) => {
                                                    handleSubmit(values)
                                                    setConfirmDelete(false);
                                                }}
                                                onClose={() => {
                                                    setConfirmDelete(false);
                                                }}
                                            />
                                        </Toolbar>
                                    </form>
                                )
                            }}
                        />
                        <Form
                            initialValues={{}}
                            submitOnEnter={false}
                            onSubmit={() => {
                                getGdprData(client, notify);
                            }}
                            keepDirtyOnReinitialize
                            sanitizeEmptyValues={false}
                            render={({handleSubmit, form, submitting, pristine, values}) => {
                                return (
                                    <form>
                                        <Toolbar className={classes.toolbar}>
                                            <Button
                                                variant="contained"
                                                onClick={() => setConfirmRequest(true)}
                                                startIcon={<SaveIcon/>}
                                            >
                                                Request all data
                                            </Button>
                                            <Confirm
                                                isOpen={confirmRequest}
                                                title="Request data"
                                                content={`Are you sure you want to request your data? It will be emailed shortly.`}
                                                onConfirm={() => {
                                                    handleSubmit(values)
                                                    setConfirmRequest(false);
                                                }}
                                                onClose={() => {
                                                    setConfirmRequest(false);
                                                }}
                                            />
                                        </Toolbar>
                                    </form>
                                )
                            }}
                        />
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

const finishTutorial = (client) => {
    client.patch("/user", {finishedTutorial: true});
}

const setupWebshop = (client, webshop, user) => {
    if (webshop === 'WooCommerce') {
        client.post("/woocommerce/oauthsigninurl", {url: user.wooCommerceBaseAddress}).then(({data}) => {
            window.location.assign(data.url);
        })
    }
    else if (webshop === 'Shopify') {
        window.open('https://apps.shopify.com/finway', '_blank');
    }
}

const setupAccounting = (client, accounting, notify) => {
    if (accounting === 'Asperion') {
        client.get("/asperion/oauthsigninurl").then(({data}) => {
            window.location.assign(data.url);
        })
    }
}

const setupSalesMargin = (client, percentage, notify) => {
    client.patch("/user", {salesMarginPercentage: parseFloat(percentage)}).then(({data}) => {
        notify("Sales Margin updated", "info")
    });
}

const setupSupplier = (client, user, supplier, notify) => {
    client.patch("/user", {...user, suppliers: [supplier]}).then(({data}) => {
        notify("Supplier selected", "info")
    }).catch((e) =>
        notify("Something went wrong", "error")
    )
}

const setupAutomaticOrderPlacement = (client, automaticOrderPlacement, notify) => {
    client.patch("/user", {automaticOrderPlacement: automaticOrderPlacement}).then(({data}) => {
        notify("Preferences saved", "info")
    }).catch((e) =>
        notify("Something went wrong", "error")
    )
}

const getGdprData = (client, notify) => {
    client.get("/actions/gdpr").then(({data}) => {
        notify("GDPR Data requested", "info")
    }).catch((e) =>
        notify("Something went wrong", "error")
    )
}

export default Configuration;
