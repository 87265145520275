import {Edit, FormWithRedirect, SelectInput, TextInput, Toolbar, NumberInput} from "react-admin";
import * as React from "react";
import {Box, Card, CardContent, Typography} from "@material-ui/core";

const ProductEdit = props => {
    return (
        <Edit
            title={"Edit Product"}
            component="div"
            {...props}
        >
            <ProductForm/>
        </Edit>
    );
};
const ProductForm = (props) => {
    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Product
                            </Typography>
                            <Box mt="1em"/>
                            <Box display={{md: 'block', lg: 'flex'}}>
                                <Box flex={2} mr={{md: 0, lg: '1em'}}>
                                    <TextInput
                                        type="text"
                                        source="destinationId"
                                        fullWidth
                                    />
                                    <SelectInput source="status" label="Product Status" fullWidth
                                                 initialValue={"InProgress"}
                                                 choices={[
                                                     {id: 'InProgress', name: 'Sync'},
                                                     {id: 'BlackListed', name: 'Black Listed'},
                                                     {id: 'Done', name: 'Done'},
                                                     {id: 'Failed', name: 'Failed'},
                                                 ]}/>
                                    <NumberInput source="salesMarginPercentage" label="Selling Margin" fullWidth />
                                </Box>
                            </Box>
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                undoable={true}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="product"
                            />
                        </CardContent>
                    </form>
                </Card>
            )}
        />
    );
};
export default ProductEdit;