import VisitorIcon from '@material-ui/icons/People';

import CustomerList from './CustomerList';
import CustomerEdit from "./CustomerEdit";

const Customers = {
    list: CustomerList,
    icon: VisitorIcon,
    edit: CustomerEdit
};

export default Customers;
