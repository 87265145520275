import {Create} from "react-admin";

import * as React from "react";
import {useEffect, useState} from "react";
import {Card} from "@material-ui/core";
import ProductForm from "./ProductForm";

const ProductCreate = props => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems([{originId: 123124}])
    }, [])
    useEffect(() => {
    }, [items])
    return (
        <Card>
            <Create
                title="Add Product"
                component="div"
                {...props}
            >
                <ProductForm/>
            </Create>
        </Card>
    );
};

export default ProductCreate;