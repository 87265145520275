import * as React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import CardWithIcon from './CardWithIcon';

const NbProcessingOrders = ({ value }) => {
    return (
        <CardWithIcon
            to="/order"
            icon={ShoppingCartIcon}
            title={'Processing Orders'}
            subtitle={value === 0 ? "0" : value}
        />
    );
};

export default NbProcessingOrders;
