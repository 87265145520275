import React, {useState} from "react";
import {Button, useNotify, useRefresh,} from "react-admin";
import SendIcon from "@material-ui/icons/Send";
import {useSelector} from "react-redux";

const PostBulkActionButtons = props => {
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const client = useSelector(state => state.user.client);
    return (
        <Button label="Invoice" disabled={loading} onClick={() => sendInvoices(client, props.selectedIds, setLoading, notify, refresh())}>
            <SendIcon/>
        </Button>
    );
}

const sendInvoices = (client, selectedIds, setLoading, notify, refresh) => {
    setLoading(true)
    client.post("actions/SendInvoices", {orderIds: selectedIds}).then(() => {
        notify('Orders invoiced', 'info')
        setLoading(false)
    })
}

export default PostBulkActionButtons;