import * as React from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Title, useDataProvider, useLogout, useNotify} from 'react-admin';
import {useHistory} from "react-router";


const ShopifyAuthCallback = () => {
    const client = useSelector(state => state.user.client);
    const user = useSelector(state => state.user);
    const history = useHistory();
    const notify = useNotify();
    const logout = useLogout();
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (!user.user.id) {
            history.replace(`/login${window.location.search}&redirectUrl=/authcallback/shopify`)
        }
        const urlParams = new URLSearchParams(window.location.search);
        let shopifyData = {};

        for (const entry of urlParams.entries()) {
            shopifyData[entry[0]] = entry[1];
        }
        if (!shopifyData?.code) {
            history.push('/configuration')
        }
        dataProvider.getOne('user', {id: null})
            .then(({data}) => {
                if (data.webshopProvider != null) {
                    if (data.shopifyData?.shopName == shopifyData.shop){
                        client.post("/shopify/oauthconfirmsignup", {...shopifyData}).then(({data}) => {
                            history.push('')
                        });
                        return;
                    }
                    let linkToCurrent = window.confirm(`Do you want to overwrite ${shopifyData.shop} to this Finway account?`);
                    if (!linkToCurrent) {
                        logout({}, `/login${window.location.search}&redirectUrl=/authcallback/shopify`);
                        return;
                    }
                }
                client.post("/shopify/oauthconfirmsignup", {...shopifyData}).then(({data}) => {
                    notify("Shopify Successfully Initialized", "info")
                    history.push('')
                });
            })

    }, [client, history, user, notify])

    return (
        <Title title="Auth"/>
    );
};

export default ShopifyAuthCallback;
