import * as React from 'react';
import classnames from 'classnames';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
    container: {minWidth: '35em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
});

const Totals = ({record}) => {
    const classes = useStyles();
    return (
        <Table className={classes.container}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        Sum
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        {(record.total.value - record.shippingTotal.value - record.totalTax.value).toLocaleString(undefined, {
                            style: 'currency',
                            currency: record.total.currency,
                        })}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        Delivery
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        {record.shippingTotal.value.toLocaleString(undefined, {
                            style: 'currency',
                            currency: record.shippingTotal.currency,
                        })}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        Taxes
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        {record?.totalTax.value.toLocaleString(undefined, {
                            style: 'currency',
                            currency: record.totalTax.currency,
                        })}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.boldCell}>
                        Total
                    </TableCell>
                    <TableCell
                        className={classnames(
                            classes.boldCell,
                            classes.rightAlignedCell
                        )}
                    >
                        {record?.total.value.toLocaleString(undefined, {
                            style: 'currency',
                            currency: record.total.currency,
                        })}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default Totals;
