import * as React from 'react';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {addDays, subDays} from 'date-fns';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const lastDay = new Date();
const lastMonthDays = Array.from({length: 30}, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date) =>
    new Date(date).toLocaleDateString();


const OrderChart = () => {
    const [data, setData] = useState([]);
    const client = useSelector(state => state.user.client);
    useEffect(() => {
        client.get(`totals/revenueperday?filters[date][from]=${aMonthAgo.toISOString()}`).then(({data}) => {
            let mappedData = data.map(d => ({dateTime: new Date(d.dateTime), revenue: d.revenue}))
            let lastDays = lastMonthDays.map(date => ({
                date: date.getTime(),
                total: mappedData.find(md => md.dateTime.getMonth() === date.getMonth() && md.dateTime.getDate() === date.getDate())?.revenue ?? 0
            }))
            setData(lastDays)
        })
    }, [client])
    return (
        <Card>
            <CardHeader title={'Revenue History'}/>
            <CardContent>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <AreaChart data={data}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#1F3C88"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#1F3C88"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                name="Date"
                                type="number"
                                scale="time"
                                domain={[
                                    addDays(aMonthAgo, 1).getTime(),
                                    new Date().getTime(),
                                ]}
                                tickFormatter={dateFormatter}
                            />
                            <YAxis dataKey="total" name="Revenue" unit="€"/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip
                                cursor={{strokeDasharray: '3 3'}}
                                formatter={value =>
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }).format(value)
                                }
                                labelFormatter={(label) =>
                                    dateFormatter(label)
                                }
                            />
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#1F3C88"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};


export default OrderChart;
