import * as React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    rightAlignedCell: {textAlign: 'right'},
});
const ShowBasket = ({record}) => {
    const classes = useStyles();
    if (!record) return null;
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Sku
                    </TableCell>
                    <TableCell>
                        Reference
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Unit Price
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Quantity
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Backorder
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Delivered
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Total
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.lineItems.map(
                    (item) =>
                        (
                            <TableRow key={item.productId}>
                                <TableCell>
                                    <span>{item.sku}</span>
                                </TableCell>
                                <TableCell>
                                    <span>{item.name}</span>
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.productPrice.value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: item.productPrice.currency,
                                    })}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.quantity}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.backorder}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.delivered}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.total.value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: item.total.currency,
                                    })}
                                </TableCell>
                            </TableRow>
                        )
                )}
            </TableBody>
        </Table>
    );
};

export default ShowBasket;
