import {LOGIN} from './configuration/actions';
import {FinwayClient} from "./library/client";
import jwtDecode from "jwt-decode";

let idToken = window.localStorage.getItem("id_token");
const userNull = {
    name: null,
    id: null,
};
const userReducer = (
    previousState = {
        token: idToken ? idToken : null,
        user: idToken ? (() => {
            let user = jwtDecode(idToken);
            return {
                email: user.email,
                name: user.name,
                id: user.sub,
            }
        })() : userNull,
        client: FinwayClient(idToken)
    },
    action
) => {
    if (action.type === LOGIN) {
        window.localStorage.removeItem('id_token');
        window.localStorage.setItem('id_token', action.payload.token);
        return {
            ...previousState,
            user: action.payload.user,
            token: action.payload.token,
            client: FinwayClient(action.payload.token)
        }
    }
    return previousState;
};

export default userReducer;