import * as React from 'react';
import {useCallback, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {useSelector} from "react-redux";
import {useNotify} from "react-admin";
import _ from "lodash";

const useStyles = makeStyles({
    rightAlignedCell: {textAlign: 'right'},
    width: {
        maxWidth: '3em'
    }
});
const BasketEdit = ({record}) => {
    const [backOrderValues, setBackorderValues] = useState(record.lineItems?.map(li => ({
        id: li.id,
        backorder: li.backorder ?? 0,
        delivered: li.delivered ?? 0,
        quantity: li.quantity
    })));
    const notify = useNotify();
    // const throttler = _.debounce(updateLineItem, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceUpdateLineItem = useCallback(_.debounce(updateLineItem, 400), [])
    const classes = useStyles();
    const client = useSelector(state => state.user.client);
    if (!record) return null;
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Reference
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Unit Price
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Quantity
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Backorder
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Delivered
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Supplier
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Total
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.lineItems.map(
                    (item, index) =>
                        (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <span>{item.name}</span>
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.productPrice.value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: item.productPrice.currency,
                                    })}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.quantity}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    <TextField
                                        type="number"
                                        size="small"
                                        inputProps={{min: 0}}
                                        value={backOrderValues[index]?.backorder}
                                        className={classes.width}
                                        onChange={({target}) => {
                                            setBackorderValues(backOrderValues.map(li => {
                                                if (li.id === item.id) {
                                                    const backOrder = parseInt(target.value) > parseInt(li.quantity) ? parseInt(li.quantity) : parseInt(target.value);
                                                    let newLineItem = {
                                                        ...li,
                                                        backorder: backOrder,
                                                        delivered: (li.quantity - backOrder)
                                                    }
                                                    debounceUpdateLineItem(client, record.id, newLineItem, notify)
                                                    return newLineItem
                                                }
                                                return li;
                                            }))
                                        }}
                                    />
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    <TextField
                                        inputProps={{min: 0}}
                                        min={0}
                                        type="number"
                                        size="small"
                                        value={backOrderValues[index]?.delivered}
                                        onChange={({target}) => {
                                            setBackorderValues(backOrderValues.map(li => {
                                                if (li.id === item.id) {
                                                    const ordered = parseInt(target.value) > li.quantity ? li.quantity : parseInt(target.value);
                                                    let newLineItem = {
                                                        ...li,
                                                        delivered: ordered,
                                                        backorder: (li.quantity - ordered)
                                                    }
                                                    debounceUpdateLineItem(client, record.id, newLineItem, notify)
                                                    return newLineItem
                                                }
                                                return li;
                                            }))
                                        }}
                                        className={classes.width}
                                    />
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.supplier}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {item.total.value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: item.total.currency,
                                    })}
                                </TableCell>
                            </TableRow>
                        )
                )}
            </TableBody>
        </Table>
    );
};

const updateLineItem = (client, orderId, lineItem, notify) => {
    client.patch(`/order/${orderId}/lineitem/${lineItem.id}`, {
        backorder: lineItem.backorder,
        delivered: lineItem.delivered
    }).then(() => {
        notify('Item Updated', 'info');
    });
}

export default BasketEdit;
