import * as React from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Title} from 'react-admin';
import {useHistory} from "react-router";



const AsperionAuth = () => {
    const client = useSelector(state => state.user.client);
    const user = useSelector(state => state.user);
    const history = useHistory();

    useEffect(() => {
        if (!user){
            history.replace('/login')
        }
        const urlParams = new URLSearchParams(window.location.search);
        let asperionData = {};

        for (const entry of urlParams.entries()) {
            asperionData[entry[0]] = entry[1];
        }
        if (!asperionData?.code){
            history.push('/configuration')
        }
        client.post("/asperion/authtokencallback", {authToken: asperionData.code}).then(() => {
            history.push('/configuration')
        });
    })

    return (
        <Title title="Auth"/>
    );
};

export default AsperionAuth;
