import * as React from "react";

const SkuField = ({ source, record = {} }) => {
    let sku = record.productVariants.length > 0 ? record.productVariants.map(i => i.sku).slice(1).reduce((acc, val) => `${acc}, ${val}`, record.productVariants[0].sku) : record.sku;
    return (
        <span>{sku}</span>
    )
};


export default SkuField;