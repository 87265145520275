import dataProvider from './dataProvider';
import {createBrowserHistory} from "history";
import createAdminStore from "./createAdminStore";
import {FirebaseAuthProvider} from "react-admin-firebase";
import firebaseConfig from "./firebaseConfig";

const authProvider = FirebaseAuthProvider(firebaseConfig, {persistence: "local"});
const history = createBrowserHistory();

export const History = history;

export const AuthProvider = {
    ...authProvider,
    logout : () => {
        return authProvider.logout().then(() => {
            window.localStorage.removeItem('id_token');
        })
    },
    getPermissions: () => {
        return authProvider.getPermissions().then(result => {
            return result === null ? 'guest' : result;
        }).catch(e => {
            return 'guest';
        })
    }
};

export const DataProvider = dataProvider;

export const Store = createAdminStore({
    authProvider,
    dataProvider,
    history,
});