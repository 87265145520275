import {Admin, Resource} from 'react-admin';
import './App.css';
import React, {useEffect} from "react";
import customers from './customers';
import orders from './orders';
import products from './products';
import {Layout} from './layout';
import customRoutes from './routes';
import {Provider} from "react-redux";
import Login from './layout/Login'
import {AuthProvider, DataProvider, History, Store} from "./store";
import firebase from "firebase/app";
import Dashboard from "./dashboard/Dashboard";
import "firebase/auth"

const App = () => {
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                window.localStorage.removeItem('id_token');
            }
        });

    })
    return <Provider
        store={Store}
    >
        <Admin title="Finway"
               dataProvider={DataProvider}
               customRoutes={customRoutes}
               authProvider={AuthProvider}
               layout={Layout}
               loginPage={Login}
               history={History}
               dashboard={Dashboard}>
            <Resource name="customer" {...customers} />
            <Resource name="edc/product" {...products} />
            <Resource name="order" {...orders} />
            <Resource name="user"/>
        </Admin>
    </Provider>
};


export default App;
